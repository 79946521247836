import { FontFamily } from '~/local-fonts'

const getLocalFonts = async () => {
  const collection: FontFamily[] = []
  if ('queryLocalFonts' in window){
    try{
      const fonts = await window.queryLocalFonts()
      fonts.forEach(font => {
        const existingFamily = collection.find(family => family.name === font.family)
        if(existingFamily){
          existingFamily.styles.push(font)
        }else{
          collection.push({
            name: font.family,
            styles: [font]
          })
        }
      })
    }catch(e){
      console.error(e)
    }
  }

  return collection
}

export { getLocalFonts }