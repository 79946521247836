import { reportError } from '~/services/errors'
import { fontVariantToObject, serializeFont } from '~/services/fonts/font-collection'
import { supabase } from '~/services/supabase'
import { CharacterTextStyles, SerializeStylesProps, TextStyles } from '~/types/editor/text-styles'
import { Database } from '~/types/supabase'

type SeriesId = Database['public']['Tables']['series']['Row']['id']
type TextStyleId = Database['public']['Tables']['text_styles']['Row']['id']

const getTextStyles = async (parent_series?: SeriesId) => {
  const query = parent_series ? (
    supabase
      .from('text_styles')
      .select('*')
      .eq('parent_series', parent_series)
  ) : (
    supabase
      .from('text_styles')
      .select('*')
  )
  const { data, error } = await query
  if (error) {
    reportError(error)
  }
  const textStyles = (data ?? []).map(entry => {
    const style: TextStyles = {
      meta: entry,
      ...JSON.parse(entry.definition)
    }
    return style
  })
  return textStyles
}

const serializeStyles = ({ name, textStyle, ignoreTransforms=false }: SerializeStylesProps) => {
  const serializedStyles: TextStyles = {
    path: name,
    meta: textStyle.meta,
    props: {
      ...textStyle.props,
      ...serializeFont(textStyle)
    },
    strokes: textStyle.strokes,
    shadows: textStyle.shadows,
    transforms: ignoreTransforms ? [] : textStyle.transforms
  }
  return JSON.stringify(serializedStyles)
}

const serializeCharStyles = (charStyles: CharacterTextStyles) => {
  const serializedCharStyles: CharacterTextStyles = charStyles.map(entry => {
    let font = {}
    if(entry.styles.props?.fontFamily && entry.styles.props.fontVariant){
      font = {
        fontFamily: {
          name: entry.styles.props.fontFamily.name,
          styles: entry.styles.props.fontFamily.styles.map(entry => fontVariantToObject(entry))
        },
        fontVariant: fontVariantToObject(entry.styles.props.fontVariant)
      }
    }
    return {
      indices: entry.indices,
      styles: {
        props: {
          ...entry.styles.props,
          ...font
        },
        transforms: []
      }
    }
  })
  return JSON.stringify(serializedCharStyles)
}

const insertTextStyle = async (textStyle: TextStyles, name: string, parent_series?: SeriesId) => {  
  const row: Database['public']['Tables']['text_styles']['Insert'] = {
    name,
    definition: serializeStyles({ textStyle, name, ignoreTransforms: true }),
    parent_series
  }
  
  const { error } = await supabase
    .from('text_styles')
    .insert([row])
  if (error) {
    reportError(error)
  }
}

const deleteTextStyle = async (textStyleId: TextStyleId) => {
  const { data, error } = await supabase
    .from('text_styles')
    .delete()
    .eq('id', textStyleId)
  if (error) {
    reportError(error)
  }
  return data
}

const updateTextStyle = async (textStyleId: TextStyleId, textStyle: TextStyles, name: string) => {
  const row: Database['public']['Tables']['text_styles']['Update'] = {
    name,
    definition: serializeStyles({ textStyle, name, ignoreTransforms: true })
  }
  const { error } = await supabase
    .from('text_styles')
    .update(row)
    .eq('id', textStyleId)
  if (error) {
    reportError(error)
  }
}

export {
  getTextStyles,
  insertTextStyle,
  deleteTextStyle,
  updateTextStyle,
  serializeStyles,
  serializeCharStyles
}